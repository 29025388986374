<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from "./components/index";
export default {
  name: "App",
  metaInfo: {
    title: "悬浮时间助手 — 毫秒级悬浮秒表",
    htmlAttrs: {
      lang: "zh-CN",
    },
    meta: [
      { name: "description", content: "悬浮时间助手是一款专业的秒表计时工具，支持全网各个主流电商平台的服务器时间，毫秒级精准延迟校准。" },
      {
        name: "keywords",
        content:
          "高刷检测,快快抢,多多流量,一鹿拼,抢茅台,巨好抢,浮动时间,数字藏品,秒杀秒表,茅台商城,悬浮秒表,橘多多,盯淘,刷新率,zkba,刷新支付,抢茅台,帧率,聚好抢,刷屏神器,悬浮提词器,抢茅台",
      },
    ]
  },
  components: {
    Index,
  },
};
</script>

<style lang="scss">
body {
  font-family: LarkEmojiFont, LarkChineseQuote, -apple-system,
    BlinkMacSystemFont, Helvetica Neue, Tahoma, PingFang SC, Microsoft Yahei,
    Arial, Hiragino Sans GB, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
  height: 100%;
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-variant-ligatures: no-common-ligatures;
  color: #2c2c2c;
  background-size: 482px auto;
  -webkit-print-color-adjust: exact;
  overflow: hidden;
}
/* html {
  @include root-font-size();
} */
</style>
