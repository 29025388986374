<template>
  <div class="container">
    <header>
      <div class="main-header">
        <div class="left">
          <img
            src="../assets/logo.png"
            alt=""
          >
        </div>
        <div class="right">
          <a href="">
             <div
              class="home"
              @click="()=>{swiper.slideTo(1,0)}"
            >首页
              
            </div>
          </a>
          <div
            class="download-btn"
            @click="()=>{swiper.slideTo(5,0)}"
          >下载APP</div>
        </div>
      </div>
    </header>
    <img class="time_bg"
      src="../assets/time_bg.png"
      alt=""
    >
    <div class="content-contanier">
      <div class="content-box">
        <div class="content">
          <swiper
            class="swiper-view"
            ref="mySwiper"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="(item,index) in list"
              :key="index"
            >
              <div
                class="item"
                :style="{backgroundImage:`url('${item.url}')`}"
              >
              </div>
            </swiper-slide>
            <swiper-slide style="background-color: rgba(210, 234, 222, .4)">
              <div class="download-wrap">
                <div class="left"></div>
                <div class="line"></div>
                <div class="right">
                  <a
                    href="https://itunes.apple.com/cn/app/id1615735198?mt=8"
                    class="download-btn ios"
                    target="_blank"
                  ></a>
                  <a
                    download="时间悬浮助手.apk"
                    href="https://cdn.jiguo.com/app/float/time.apk"
                    class="download-btn android"
                  ></a>
                </div>
              </div>
            </swiper-slide>
            
          </swiper>
          <div
            class="swiper-pagination"
            slot="pagination"
          >
          </div>
        </div>

      </div>
    </div>
    <footer>
      <p>Copyright © 2022 时间悬浮助手 All rights reserved. 京ICP备18047487号 | 京ICP备18047487号-5A</p>
      <p>北京翰鼎翔睿科技有限公司</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      swiperOptions: {
        // autoplay: true,
        loop: true,
        speed: 1000,
        mousewheel: true,
        direction: "vertical",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
      },
      list: [
        {
          url: require("../assets/1.png"),
          desc:
            "",
        },
        {
          url: require("../assets/2.png"),
          desc:
            "",
        },
        {
          url: require("../assets/3.png"),
          desc:
            "",
        },
        {
          url: require("../assets/4.png"),
          desc:
            "",
        }
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="scss">
header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;

  .main-header {
    width: 1100px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  .left {
    height: 48px;
    img {
      height: 100%;
    }
  }
  .right {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 17px;
    a{
      text-decoration: none;
      color: black;
    }
  }
  .feedback {
    margin-left: 36px;
    text-decoration: none;
    color: #2c2c2c;
  }
  .download-btn {
    width: 88px;
    height: 33px;
    margin-left: 36px;
    line-height: 33px;
    text-align: center;
    background-color: #4FC78B;
    color: #fff;
    border-radius: 33px;
    cursor: pointer;
  }
}
.content-contanier {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  .content-box {
    width: 100%;
    height: 100%;
  }
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiper-view {
      width: 848px;
      height: 482px;
    }
  }
}

.container {
  .time_bg{
    position: absolute;
    left: 50%;
    transform: translateX(189px);
    width: 370px;
    height: 338px;
  }
  .swiper-container-vertical > .swiper-pagination-bullets {
    right: 10%;
  }
  .swiper-pagination{
    display: flex;
    flex-direction: column;
    height: 186px;
    margin-left: 880px;
    margin-top: -293px;
  }
  .swiper-pagination-bullet {
    width: 2px;
    height: 42px;            
    background-color: #B9E1CD;
    border-radius: 0;
  }
  // .swiper-container-vertical
  //   > .swiper-pagination-bullets
  //   .swiper-pagination-bullet:first-of-type {
  //   margin-top: 0;
  // }
  .swiper-pagination-bullet-active {
    background-color: #50C78E;
  }
  .swiper-wrapper {
    transition-timing-function: ease-in-out;
    
  }
}
.item {
  width: 848px;
  height: 482px;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 774px 521px;
  background-color: rgba(210, 234, 222, .4);
  .desc {
    width: 241px;
    position: absolute;
    left: 571px;
    top: 69px;
    font-size: 12px;
    line-height: 17px;
  }
}

.download-wrap {
  
  display: flex;
  justify-content: center;
  padding-top: 190px;

  .left {
    width: 84px;
    height: 94px;
    background: url("../assets/download.png") no-repeat center center;
    background-size: cover;
  }
  .line {
    width: 2px;
    height: 44px;
    background-color: #B9E1CD;
    margin: auto 75px;
  }
  .download-btn {
    display: block;
    width: 152px;
    height: 33px;
    &.ios {
      background: url("../assets/ios.png") no-repeat center center;
      background-size: 100% auto;
    }
    &.android {
      margin-top: 28px;
      background: url("../assets/android.png") no-repeat center center;
      background-size: 100% auto;
    }
  }
}
footer {
  position: fixed;
  bottom: 42px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #808080;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  z-index: 1;
}
</style>