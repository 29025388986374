// 引入cdn路径
// 引入cdn路径
import '../../../../config/public-path'

import axios from 'axios'

// 引入依赖库
import Vue from 'vue'
import VueMeta from 'vue-meta'
import {
	Swiper as SwiperClass,
	Pagination,
	Mousewheel,
	Autoplay,
	Scrollbar
} from 'swiper/js/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

// 引入css
import 'reset.css'

// import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'

// 引入组件
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// 注册插件
Vue.use(VueMeta)
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  mounted() {
    // 预渲染时注入动态publicPath
    if (window.__PRERENDER_INJECTED__) {
      const page_script = document.createElement('script')
      page_script.innerHTML = `window.__page_name__="${window.__PRERENDER_INJECTED__.page_name}"`
      document.querySelector('head').appendChild(page_script)
    }

    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
